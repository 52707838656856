  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    z-index: 1;
    padding-top: 15px;
  }

  .dropdown-content button {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    background-color: white;
    border: transparent;
    cursor: pointer;
    border-bottom: 2px solid gray;
    width: 100%;
  }
  .dropdown-content button:hover {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    background-color: #C53720;
    border: transparent;
    cursor: pointer;
    border-bottom: 2px solid gray;
    width: 100%;
  }
  .dropdown-content a:hover {background-color: white;}

  .dropdown:hover .dropdown-content {display: block;}

  .dropdown:hover .dropbtn {background-color: white;}