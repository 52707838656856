.BodyWrapper{
    width: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    z-index: 1000;
}
.Container{
    max-width: 1250px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
}
.Wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
}
.Wrapper ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 45%;
}
.Wrapper i {
    display: none;
}
@media only screen and (max-width: 872px) {
    .Container{
        width: 90%;
    }
    .Wrapper img {
        width: 120px;
    }
    .Language{
        display: none;
    }
    .Wrapper ul {
        display: none;
    }
    .Wrapper button {
        display: none;
    }
    .Wrapper i {
        display: inline;
        font-size: 34px;
        color:#ed1c24
    }
  }

.Wrapper ul li {
    list-style-type: none;
}
.link{
    text-decoration: none;
    cursor: pointer;
}
.link p{
    margin: 0;
    padding: 0;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}
.Wrapper button {
    background-color: transparent;
    padding: 10px 10px;
    border: 2px solid red;
}
.Wrapper a {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
display: flex;
align-items: center;
text-align: center;
color: #000000;
}
.spans{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    cursor: pointer;
}
.spans button{
background-color: #000000 !important;
color: white !important;
padding: 5px !important;
}