.Wrapper{
    background-image: url("./../../assets/not-found.png");
    background-size: cover;
    width: 100%;
    height: 100vh;
}
.Container{
    max-width: 1250px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
}
@media only screen and (max-width:882px) {
    .Container{
        width: 90%;
    }
    .Wrapper{
        background-image: url("./../../assets/not-found.png");
        background-size: cover;
        width: 100%;
        height: 100vh;
        background-position-x: 80%;
    }
}
.Container h2{
    width: 60%;
    color: white;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 38px !important;
    color: #FFFFFF;
}
.link{
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    text-decoration: none;
}
.Container h1{
        margin: 0;
        padding: 0;
            font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 100px;
         padding-top: 20%;
        -webkit-text-stroke: 2px red;
}
.Container p{
    color: white;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    width: 50%;
}
@media only screen and (max-width:550px) {
    .Container p{
        color: white;
        font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        color: #FFFFFF;
        width: 60%;
    }
    .Container h1{
        -webkit-text-stroke: 2px red;
    }
    .Container h2{
        padding-top: 20vh;
        width: 100%;
        color: white;
        font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 54px;
        color: #FFFFFF;
    }
}
.Container button {

    border: 2px solid #C53720;
    background-color: transparent;
    padding:15px;
    cursor: pointer;
}