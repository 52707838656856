.WrapperBody{
    background-image: linear-gradient( rgba(6, 5, 5, 0.945), rgba(0, 0, 0, 0.895)),  url("./../../assets/home/footer-background.png" );
    width: 100%;
    height: 222px;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 52px;
    padding-top: 52px;
}
.Container{
    max-width: 1250px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
}
@media only screen and (max-width:882px) {
    .Container{
        width: 90%;
        
    }
    .WrapperBody{
        height: 100%;
    }
}
.Wrapper ul li{
    text-decoration: none;
    list-style-type: none;
    margin-bottom: 15px;
}
.Wrapper ul li a i{
    color:#c53720;
    font-size: 24px;
    margin-right: 5px;

}
.Wrapper ul li a {
text-decoration: none;
}
.FooterLink{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    
    color: #FFFFFF;
    
    opacity: 0.5;
}
.Paragraph{
    font-family: 'Actor';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
color: #FFFFFF;
opacity: 0.5;
}
.FooterLinkHead{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}
.link{
    text-decoration: none;
}
.Container button {
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    border: 2px solid #C53720;
    background-color: transparent;
    padding:10px 20px;
    cursor: pointer;
}
.linkCompany{
    text-align: center;
    width: 100%;
    color: gray;
    font-size: 14px;
    text-decoration: none;
    font-family: 'Roboto';
}